



























































































































































import { getAPI } from "@/api/axios-base";
import {
  notificationError,
  notifyError,
  notifyInfo,
  notifySuccess,
} from "@/components/Notification";
import { AxiosError } from "axios";
import Vue from "vue";
import { mapActions } from "vuex";
import BtnCreateMedicalLocation from "../buttons/BtnCreateMedicalLocation.vue";

export default Vue.extend({
  components: { BtnCreateMedicalLocation },
  name: "medical-locations",
  data() {
    return {
      search: "",
      seeCancels: false,
      entity: "getMedicalLocations",
      items: [],
      loading: false,
      uuid: "",
      dialogConfirmRemove: false,
      DialogEdit: false,
      itemSelect: {},
      headers: [
        {
          text: "Name",
          align: "start",
          value: "name",
        },
        { text: "", align: "end", value: "actions", sortable: false },
      ],
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    ...mapActions("crmMedicFormModule", []),
    getList(): void {
      this.loading = true;
      getAPI("/patient/getMedicalLocations")
        .then((response) => {
          this.items = response.data;
          this.loading = false;
        })
        .catch(() => {
          notificationError();
          this.loading = false;
        });
    },
    cancel() {
      this.DialogEdit = false;
      this.dialogConfirmRemove = false;
      this.itemSelect = {};
    },
    showRemoveDialog(item: any): void {
      this.itemSelect = item;
      this.dialogConfirmRemove = true;
    },
    showEditDialog(item: any): void {
      this.itemSelect = item;
      this.DialogEdit = true;
    },
    remove(): void {
      this.loading = true;
      getAPI
        .delete(
          `/patient/deleteMedicalLocation/${(this as any).itemSelect.uuid}`
        )
        .then(async () => {
          await this.getList();
          this.loading = false;
          this.dialogConfirmRemove = false;
          notifyInfo("The location has been removed");
        })
        .catch((error: any) => {
          this.loading = false;
          let mess = error.response.data.message.toString();

          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },
    update(): void {
      this.loading = true;
      getAPI
        .put(
          `/patient/updateMedicalLocation/${(this as any).itemSelect.uuid}`,
          {
            name: (this as any).itemSelect.name,
          }
        )
        .then(async () => {
          await this.getList();
          this.loading = false;
          this.dialogConfirmRemove = false;
          notifySuccess("The location has been updated");
          this.cancel();
        })
        .catch((error: any) => {
          this.loading = false;
          let mess = error.response.data.message;

          if (mess.includes("[")) {
            mess = mess.replace("[", "");
            mess = mess.replace("]", "");
          }
          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },
  },
});
